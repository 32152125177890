import { message } from "antd";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import i18next from "i18next";
import Cookies from "js-cookie";

import { Env } from "@/config/env";
import {
  SYSTEM_BLACK_LIST,
  TOKEN_EXPIRE,
  TOKEN_IN_COOKIE,
} from "@/libs/constants";
import { IRefreshToken } from "@/types/auth";
import { IResolve } from "@/types/communal";
import { debounce, getTokenExp } from "@/utils/other";
import { localRemove, localSet } from "@/utils/storage";
const CancelToken = axios.CancelToken;

const source = CancelToken.source();

const updateToken = () => {
  api
    .post(`/auth/refreshToken`)
    .then((result: AxiosResponse<IRefreshToken>) => {
      const expire = result.data.expires_in * 1000 + new Date().valueOf();

      localSet(TOKEN_EXPIRE, expire);
      Cookies.set(TOKEN_IN_COOKIE, result.data.token, {
        domain: Env.DOMAIN,
        expires: new Date(expire),
      });
    });
};

const debouncedHandleInput = debounce(updateToken, 1000);

const getLanguage = () => {
  const language = i18next.language;

  switch (language) {
    case "en":
      return "en";
    case "zhCN":
      return "zh-CN";
    case "zhTW":
      return "zh-HK";
    case "esES":
      return "es";
    default:
      return "zh-CN";
  }
};
export const baseURL = Env.API_URL + "/api";

export const api: AxiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = Cookies.get(TOKEN_IN_COOKIE);

  if (!token) {
    if (config.headers) {
      config.headers["Accept-Language"] = getLanguage();
    }
    return config;
  }
  if (config.headers) {
    config.headers.Authorization = token;
    config.headers.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // 头部加上时区标识
    config.headers["Accept-Language"] = getLanguage();
  }

  return config;
});

api.interceptors.request.use(
  (config) => {
    if (config.url === "/auth/refreshToken") {
      return config;
    }
    // 试卷预览页无需验证
    if (SYSTEM_BLACK_LIST.includes(window.location.pathname)) {
      console.log("试卷预览页无需验证");

      return config;
    }
    const token = Cookies.get(TOKEN_IN_COOKIE);

    const tokenExpire = token ? getTokenExp(token) : null;

    if (!tokenExpire) {
      return config;
    }
    const time = tokenExpire - new Date().valueOf();

    if (time <= 0) {
      Cookies.remove(TOKEN_IN_COOKIE, { domain: Env.DOMAIN });
      localRemove(TOKEN_EXPIRE);
      window.location.assign(`/login?returnUrl=${window.location.pathname}`);
      source.cancel("Request cancelled");
    }

    if (time > 0 && time < 10 * 60 * 1000) {
      debouncedHandleInput();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse<IResolve>) => {
    if (response.data.status !== 1) {
      const { message: msg } = response.data;

      message.error(msg);

      return Promise.reject(new Error(msg));
    }

    return response.data;
  },
  (error) => {
    // 請求已發出，但服務器返回狀態碼不在2xx範圍內
    if (error.response) {
      if (error.response.status === 401) {
        message.error("token expiration");
        window.location.assign(`/login?returnUrl=${window.location.pathname}`);
      } else if (error.response.data) {
        const { message: msg } = error.response.data;

        message.error(msg);
      } else {
        message.error(error.message);
      }
    } else if (axios.isCancel(error)) {
      // message.error(error.message);
    } else {
      // 請求已發出，但沒有收到響應，或 發送請求時出錯
      message.error(error.message);
    }

    return Promise.reject(error);
  }
);
